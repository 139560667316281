.center-screen { 
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding:100px;
}

.audio-box {
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
}
