.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-main {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.search-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* New CSS Grid stuff */

.grid-container {
  display: grid;
  height: calc(100vh - 88px); /* - height of navbar */
  background-color: #ffffff;
  grid-template-columns: auto auto auto;
  grid-template-rows: 350px 100px auto auto;

}

.grid-container > div {
  text-align: center;
  justify-content: center;
  align-content: center;
}

.logo {
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 4;
  font-size: 100px;
  margin-top: 75px;
}

.search {
  grid-row: 2;
  grid-column-start: 2;
  grid-column-end: 3;
  
  /* sub-grid */
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 48px;
  gap: 10px;
}

.search > .input {
  grid-column: 1 / 2;
}

.search > .button {
  grid-column: 2 / 3;
}

.description {
  grid-row: 3;
  grid-column-start: 1;
  grid-column-end: 4;
  margin-top: 20px;
  margin-bottom: 60px;
}

.footer {
  grid-row: 4;
  grid-column-start: 1;
  grid-column-end: 4;
  padding-top: 40px;
  background-color: #282c34;
  color: white;
}

.footer > .p {
  text-align: center;
}

.footer:hover {
  color: orange;
}




